export default [
  {
    path: 'businessList',
    name: 'BusinessList',
    component: () => import('@/views/visitor/BusinessList'),
    meta: {
      title: '商机列表',
      hideTabbar: true,
      ignoreLogin: true,
    }
  },
  {
    path: 'businessOportunityEdit',
    name: 'BusinessOportunityEdit',
    component: () => import('@/views/visitor/BusinessOportunityEdit'),
    meta: {
      title: '我有商机',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true,
      ignoreLogin: true,
    }
  },
  {
    path: 'businessResourcesEdit',
    name: 'BusinessResourcesEdit',
    component: () => import('@/views/visitor/BusinessResourcesEdit'),
    meta: {
      title: '我有资源',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true,
      ignoreLogin: true,
    }
  },
  {
    path: 'businessOportunityCompleted',
    name: 'BusinessOportunityCompleted',
    component: () => import('@/views/visitor/BusinessOportunityCompleted'),
    meta: {
      title: '我有商机',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true,
      ignoreLogin: true,
    }
  },
  {
    path: 'businessResourcesCompleted',
    name: 'BusinessResourcesCompleted',
    component: () => import('@/views/visitor/BusinessResourcesCompleted'),
    meta: {
      title: '我有资源',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true,
      ignoreLogin: true,
    }
  }
]
