import Vue from 'vue';
import DialogRewriteVue from './UDialog';

const defaultOptions = {
  closeBtn: true,
  title: '',
  width: '320px',
  message: '',
  callback: null,
  cancelCallback: null,
};

const DialogConstructor = Vue.extend(DialogRewriteVue);

let instance = null;

const initInstance = () => {
  instance = new DialogConstructor({
    el: document.createElement('div')
  });
  document.body.appendChild(instance.$el);
}

const DialogNew = function (options) {
  if (!instance) {
    initInstance();
  }
  const dialogOptions = Object.assign({}, defaultOptions, options);

  Object.keys(dialogOptions).forEach(prop => {
    instance[prop] = dialogOptions[prop];
  });
  Vue.nextTick(() => {
    instance.show = true;
  });
  if (typeof dialogOptions.cancelCallback === 'function') {
    instance['cancelCallback'] = dialogOptions.cancelCallback;
  }
  if (typeof dialogOptions.callback === "function") {
    instance['callback'] = dialogOptions.callback;
  } else {
    return new Promise((resolve, reject)=> {
      instance.callback = (data) => {
        resolve(data);
      };
      instance.cancelCallback = () => {
        reject();
      }
    });
  }
}

export default DialogNew;