export default [
  {
    path: 'index',
    name: 'OrdersIndex',
    component: () => import('@/views/orders/OrdersIndex'),
    meta: {
      title: '我的订单',
      showCommonHeader: true,
      hideLeftArror: true,
      keepAlive: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: 'candidateProgress',
    name: 'OrdersCandidateProgress',
    component: () => import('@/views/orders/OrdersCandidateProgress'),
    meta: {
      title: '候选人进度',
      showCommonHeader: true,
      showLeftArror: true,
    }
  },
  {
    path: 'candidateProgressAll',
    name: 'OrdersCandidateProgressAll',
    component: () => import('@/views/orders/OrdersCandidateProgress'),
    meta: {
      title: '候选人进度',
      showCommonHeader: true,
      showLeftArror: true,
    }
  },
  {
    path: 'ordersDetail/:orderId',
    name: 'OrdersDetail',
    component: () => import('@/views/orders/OrdersDetail'),
    meta: {
      title: '订单详情',
      showCommonHeader: true,
      showLeftArror: true,
      hideTabbar: true,
    }
  }
]