export function copySelfObject(toObj, fromObj = {}) {
  if (toObj && fromObj) {
    Object.keys(toObj).forEach(key => {
      if (fromObj[key] !== undefined) {
        toObj[key] = fromObj[key]
      }
    });
  }
}

export function copyObjectWithIgnoreKey(toObj, fromObj, ignoreKey) {
  if (toObj && fromObj && Array.isArray(ignoreKey)) {
    Object.keys(fromObj).forEach(key => {
      if (fromObj[key] !== undefined && ignoreKey.indexOf(key) === -1) {
        toObj[key] = fromObj[key]
      }
    });
  }
}

export function setCookie(c_name, value, expire) {
  var date = new Date()
  date.setSeconds(date.getSeconds() + expire)
  document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString()
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + "=")
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(";", c_start)
      if (c_end == -1) c_end = document.cookie.length
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ""
}

export function delCookie(c_name) {
  setCookie(c_name, "", -1)
}