export default [
  {
    path: 'user',
    name: 'AgreementUser',
    component: () => import('@/views/agreement/AgreementUser'),
    meta: {
      title: '用户协议及隐私政策',
      ignoreLogin: true,
    }
  },
  {
    path: 'service',
    name: 'AgreementService',
    component: () => import('@/views/agreement/AgreementService'),
    meta: {
      title: 'U单平台服务协议',
      ignoreLogin: true,
    }
  },
  {
    path: 'cooperation',
    name: 'AgreementCooperation',
    component: () => import('@/views/agreement/AgreementCooperation'),
    meta: {
      title: 'U单平台服务商合作协议',
      ignoreLogin: true,
    }
  },
  {
    path: 'reward',
    name: 'AgreementReward',
    component: () => import('@/views/agreement/AgreementReward'),
    meta: {
      title: '会员积分及惩罚规则',
      ignoreLogin: true,
    }
  },
  {
    path: 'about',
    name: 'About',
    component: () => import('@/views/agreement/About'),
    meta: {
      title: '关于我们',
      ignoreLogin: true,
    }
  },
]