export default [
  {
    path: 'index',
    name: 'HomeIndex',
    component: () => import('@/views/home/HomeIndex'),
    meta: {
      title: '首页',
      showCommonHeader: false,
      keepAlive: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: 'search',
    name: 'HomeSearch',
    component: () => import('@/views/home/HomeSearch'),
    meta: {
      title: '搜索',
      showCommonHeader: true,
      keepAlive: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: 'orderSearch',
    name: 'OrderSearch',
    component: () => import('@/views/home/HomeSearch'),
    meta: {
      title: '更多订单',
      showCommonHeader: true,
      keepAlive: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: 'resourcesEdit',
    name: 'HomeResourcesEdit',
    component: () => import('@/views/home/HomeResourcesEdit'),
    meta: {
      title: '我有资源',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'resourcesCompleted',
    name: 'HomeResourcesCompleted',
    component: () => import('@/views/home/HomeResourcesCompleted'),
    meta: {
      title: '我有资源',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'businessOportunityEdit',
    name: 'HomeBusinessOportunityEdit',
    component: () => import('@/views/home/HomeBusinessOportunityEdit'),
    meta: {
      title: '我有商机',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'businessOportunityCompleted',
    name: 'HomeBusinessOportunityCompleted',
    component: () => import('@/views/home/HomeBusinessOportunityCompleted'),
    meta: {
      title: '我有商机',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },

]