export default [
  {
    path: 'sqtLogin',
    name: 'SqtLogin',
    component: () => import('@/views/specialService/SqtLogin'),
    meta: {
      title: 'U单商企通',
      ignoreLogin: true,
    },
  },
  {
    path: 'jdjlLogin',
    name: 'JdjlLogin',
    component: () => import('@/views/specialService/JdjlLogin'),
    meta: {
      title: 'U单锦鲤',
      ignoreLogin: true,
    },
  },
  {
    path: 'wXLogin',
    name: 'WXLogin',
    component: () => import('@/views/specialService/wXLogin'),
    meta: {
      title: '微信授权',
      ignoreLogin: true,
    },
  },
  {
    path: 'bindPhone',
    name: 'BindPhone',
    component: () => import('@/views/specialService/BindPhone'),
    meta: {
      title: '手机号绑定',
      ignoreLogin: true,
    },
  },
  {
    path: 'jdActivityList',
    name: 'JdActivityList',
    component: () => import('@/views/specialService/jdActivityList'),
    meta: {
      title: '京东活动列表',
      ignoreLogin: true,
    },
  },
  {
    path: 'openMp',
    name: 'OpenMp',
    component: () => import('@/views/specialService/openMp'),
    meta: {
      title: '打开小程序',
      ignoreLogin: true,
    },
  },
]
