export default [
  {
    path: 'basicInformation',
    name: 'PerBasic',
    component: () => import('@/views/perfection/PerBasic'),
    meta: {
      title: '完善信息',
      showCommonHeader: true,
      hideLeftArror: true,
      hideTabbar: true,
      isWhiteBackground: true,
    }
  },
  {
    path: 'joinCompany',
    name: 'PerJoinCompany',
    component: () => import('@/views/perfection/PerJoinCompany'),
    meta: {
      title: '完善信息',
      showCommonHeader: true,
      hideLeftArror: true,
      hideTabbar: true,
      isWhiteBackground: true,
    }
  },
  {
    path: 'updateCompanyName',
    name: 'PerUpdateCompanyName',
    component: () => import('@/views/perfection/PerUpdateCompanyName'),
    meta: {
      title: '更换公司名',
      showCommonHeader: true,
      hideLeftArror: true,
      hideTabbar: true,
      isWhiteBackground: true,
    }
  },
  {
    path: 'audit',
    name: 'PerAudit',
    component: () => import('@/views/perfection/PerAudit'),
    meta: {
      title: '完善信息',
      showCommonHeader: true,
      hideLeftArror: true,
      hideTabbar: true,
      isWhiteBackground: true,
    }
  },
  {
    path: 'basicCompleted',
    name: 'PerBasicCompleted',
    component: () => import('@/views/perfection/PerBasicCompleted'),
    meta: {
      title: '完善信息',
      showCommonHeader: true,
      hideLeftArror: true,
      hideTabbar: true,
      isWhiteBackground: true,
    }
  }
]