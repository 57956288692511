import Vue from 'vue'
import Vuex from 'vuex'

import Request from "@/utils/request";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userInfo: null,
    openId: null,
  },
  getters: {
    userInfo: (state) => state.userInfo,
    getOpenId: (state) => state.openId,
  },
  mutations: {
    UPDATE_USER_INFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_OPEN_ID: (state, openId) => {
      state.openId = openId
    },
  },
  actions: {
    updateUserInfo({ commit }, userInfo) {
      commit('UPDATE_USER_INFO', userInfo)
      if (userInfo) {
        localStorage.setItem('userInfo', userInfo)
      } else {
        localStorage.removeItem('userInfo')
      }
    },
    getUserInfo({ commit }) {
      return new Promise((resolve) => {
        // const userInfoStr = localStorage.getItem('userInfo');
        // if (userInfoStr) {
        //   const userInfo = JSON.parse(userInfoStr);
        //   commit('UPDATE_USER_INFO', userInfo);
        //   resolve(userInfo);
        //   return;
        // }
        Request({
          url: '/wechat/findWechatInformation',
          method: 'get',
        }).then(
          (res) => {
            if (res.code === 200 && res.data) {
              localStorage.setItem('userInfo', JSON.stringify(res.data))
              commit('UPDATE_USER_INFO', res.data)
              resolve(res.data)
            } else {
              localStorage.removeItem('userInfo')
              commit('UPDATE_USER_INFO', null)
              resolve(null)
            }
          },
          (error) => {
            console.log('error', error)
            localStorage.removeItem('userInfo')
            commit('UPDATE_USER_INFO', null)
            resolve(null)
          },
        )
      })
    },
  },
  modules: {},
})
