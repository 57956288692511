import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vant from 'vant';
import UDialog from '@/components/UDialog';

// 引入全部样式
import 'vant/lib/index.less';
import './styles/main.less';

Vue.config.productionTip = false;

Vue.use(Vant);
Vue.prototype.$udialog = UDialog;
// 忽略打开微信小程序组件
Vue.config.ignoredElements = ['wx-open-launch-weapp']

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
