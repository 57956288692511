<template>
  <van-dialog v-model="show"
              :title="title"
              :width="width"
              class="ud-dialog"
              overlay-class="ud-overlay"
              show-cancel-button
              @confirm="onConfirm"
              @cancel="onCancel"
  >
    <div v-if="closeBtn" class="van-dialog__close" @click="onCloseBtnClick">
      <van-icon name="cross" />
    </div>
    <div class="van-dialog__message">
      {{ message }}
    </div>
  </van-dialog>
</template>

<script>
export default {
  name: "DialogRewrite",
  props: {
    title: {
      type: String,
      default: null
    },
    closeBtn: {
      type: Boolean,
      default: true,
    },
    width: {
      type: [String, Number],
      default: '320px'
    },
    message: {
      type: String,
      default: ''
    },
    callback: {
      type: Function,
      default: () => {},
    },
    cancelCallback: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      show: false
    }
  },
  mounted() {

  },
  watch: {
    // message(){
    //   console.log('message chagne');
    // },
  },
  methods: {
    onCloseBtnClick() {
      this.show = false;
    },
    onCancel() {
      if (typeof this.cancelCallback === 'function') {
        this.cancelCallback();
      }
    },
    onConfirm() {
      if (typeof this.callback === 'function') {
        this.callback();
      }
    }
  }
}
</script>

<style scoped>

</style>