import axios from 'axios';
import { Toast } from "vant";
import { delCookie } from "@/utils/common";

let baseURL = process.env.VUE_APP_BASE_API;

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER + baseURL,
  timeout: 60000, // 指定请求超时的毫秒数
});

let toast = null;
let count = 0;

// 添加请求拦截器
service.interceptors.request.use(config => {
  const token = window.localStorage.getItem('token');
  if (token && !config.ignoreHeader) {
    // config.headers.Authorization = token;
    config.headers.token = token;
  }
  if(count === 0) {
    count++;
    toast = Toast.loading({
      message: '加载中...',
      forbidClick: true,
      duration: 0,
    });
  }
  // console.log('config', config);
  return config;
})

function goToLogin() {
  localStorage.removeItem('token');
  localStorage.removeItem('userInfo');
  delCookie('token');
  location.replace('/userLogin');
}

// 添加响应拦截器
service.interceptors.response.use(res => {
  count--;
  if (count === 0) {
    toast.close();
  }
  if (res.status === 200) {
    const resData = res.data;
    if (resData && resData.code === 401) {
      goToLogin();
      return ;
    }
    return res.data || null;
  }
  const resData = res && res.data;
  const errorMessage = resData && resData.message;

  Toast(errorMessage);
  // Notify({ type: 'danger', message: '2xx范围内的错误: ' + errorMessage });
  return Promise.reject(new Error(errorMessage))
}, error => {
  count--;
  if (count === 0) {
    toast.close();
  }
  const res = error && error.response || null;
  if (res) {
    if (res.status === 401) {
      goToLogin();
      return ;
    }
  }
  const resData = res && res.data;
  const errorMessage =(resData && resData.message) || error.message || '请求失败！';
  // Notify({ type: 'danger', message: '超出2xx范围的错误: ' + errorMessage });
  Toast(errorMessage);
  return Promise.reject(new Error(errorMessage))
});

export default service;