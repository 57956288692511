import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';

import home from './modules/home';
import orders from './modules/orders';
import center from './modules/center';
import agreement from './modules/agreement';
import perfection from "./modules/perfection";
import specialService from "./modules/specialService";
import visitor from "@/router/modules/visitor";
import { delCookie } from "@/utils/common";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Welcome',
    component: () => import('@/views/Welcome'),
    meta: {
      ignoreLogin: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: '/userLogin',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Login'),
    meta: {
      ignoreLogin: true,
      keepAlive: true,
    }
  },
  {
    path: '/userRegister',
    name: 'Register',
    component: () => import('@/views/Register'),
    meta: {
      ignoreLogin: true,
      keepAlive: true,
      ignoreScrollTop: true,
    }
  },
  {
    path: '/visitor',
    name: 'Visitor',
    component: () => import('@/components/layout/MainLayout'),
    children: [...visitor]
  }, // 专项服务
  {
    path: '/specialService',
    name: 'SpecialService',
    component: { render: (e) => e("router-view") },
    children: [...specialService]
  }, // 专项服务
  {
    path: '/perfection',
    name: 'Perfection',
    component: () => import('@/components/layout/MainLayout'),
    children: [...perfection]
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('@/components/layout/MainLayout'),
    children: [...home]
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import('@/components/layout/MainLayout'),
    children: [...orders]
  },
  {
    path: '/center',
    name: 'Center',
    component: () => import('@/components/layout/MainLayout'),
    children: [...center]
  },
  {
    path: '/agreement',
    name: 'Agreement',
    component: () => import('@/components/layout/AgreementLayout'),
    children: [...agreement],
  },
  {
    path: '/*',
    name: '404',
    component: () => import(/* webpackChunkName: "login" */ '@/views/404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    if (to.meta && !to.meta.ignoreScrollTop) {
      return { y: 0 }
    }
    return savedPosition;
  },
  // scrollBehavior: () => ({ y: 0 }),
  routes
})

function checkUserIsLogin() {
  return new Promise((resolve) => {
    const localToken = window.localStorage.getItem('token');
    store.dispatch('getUserInfo').then(userInfo => {
      if (localToken && userInfo) {
        resolve(true);
        return;
      }
      resolve(false);
    });
  });
}

router.beforeEach((to, from, next) => {
  const isIgnoreLogin = to.meta && to.meta.ignoreLogin;
  if (isIgnoreLogin) {
    if (to.name === 'Welcome') {
      const userInfoStr = localStorage.getItem('userInfo');
      const userInfo = JSON.parse(userInfoStr);
      const localToken = window.localStorage.getItem('token');
      if (localToken && userInfoStr) {
        /**
         * status: 1未认证  2认证审核  3未签约  4签约审核 5已签约 6合同到期 7合同终止
         * jumpType 1:精确匹配有值  2:精确匹配无值，模糊匹配有值  3:精确匹配和模糊匹配都无值 4:认证中页面  5:微信公众号首页  6:修改公司页面
         * */
        let jumpType = userInfo.jumpType;
        if (jumpType === 1 || jumpType === 2) {
          next({
            name: 'PerJoinCompany',
            query: {
              type: jumpType
            }
          });
        } else if (jumpType === 3) {
          next({
            name: 'PerBasic',
          });
        } else if (jumpType === 4) {
          next({
            name: 'PerAudit',
          });
        } else if (jumpType === 5) {
          next({
            name: 'HomeIndex',
          });
        } else if (jumpType === 6) {
          next({
            name: 'PerUpdateCompanyName',
          });
        } else {
          next({
            name: 'HomeIndex',
          });
        }
      } else{
        next();
      }
    } else{
      next();
    }
  } else {
    checkUserIsLogin().then(res => {
      if (res) {
        next();
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('userInfo');
        delCookie('token');
        next({
          name: 'Login'
        })
      }
    });
  }
  // next();
})

export default router
