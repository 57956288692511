export default [
  {
    path: 'index',
    name: 'CenterIndex',
    component: () => import('@/views/center/CenterIndex'),
    meta: {
      title: '个人中心',
      showCommonHeader: false,
      isWhiteBackground: true
    }
  },
  {
    path: 'basicInformation',
    name: 'CenterBasicInformation',
    component: () => import('@/views/center/CenterBasicInformation'),
    meta: {
      title: '基本信息',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'opportunityList',
    name: 'CenterOpportunityList',
    component: () => import('@/views/center/CenterOpportunityList'),
    meta: {
      title: '商机列表',
      showCommonHeader: true,
    }
  },
  {
    path: 'vendorCertification',
    name: 'CenterVendorCertification',
    component: () => import('@/views/center/CenterVendorCertification'),
    meta: {
      title: '供应商认证',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'vendorCertificationDetail',
    name: 'CenterVendorCertificationDetail',
    component: () => import('@/views/center/CenterVendorCertificationDetail'),
    meta: {
      title: '供应商认证',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'contract',
    name: 'CenterContract',
    component: () => import('@/views/center/CenterContract'),
    meta: {
      title: '合同协议',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'businessCooperation',
    name: 'CenterBusinessCooperation',
    component: () => import('@/views/center/CenterBusinessCooperation'),
    meta: {
      title: '商务合作',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
  {
    path: 'businessCooperationCompleted',
    name: 'CenterBusinessCooperationCompleted',
    component: () => import('@/views/center/CenterBusinessCooperationCompleted'),
    meta: {
      title: '商务合作',
      showCommonHeader: true,
      hideTabbar: true,
      isWhiteBackground: true
    }
  },
]